import React, { Component } from "react";
import { message, Select, Alert, Icon } from "antd";
import userIcon from "../../images/po.png";
import boatIcon from "../../images/boat.png";
import bargeIcon from "../../images/barge.png";
import noneIcon from "../../images/none.png";
import "./Home.scss";
import Person from "./DrawerPerson.js";
import Zone from "./DrawerZone";
import { httpGet } from "../../http";

import moment from 'moment'
const dateFormat = 'MM-DD HH:mm'

const { Option } = Select;
let map = {};

export default class Home extends Component {
  state = {
    drawerPersonShow: false,
    currentMarker: {},
    drawerZoneShow: false,
    currentZone: '',
    zoneData: [],
    marker_show_onsite: [],
    all_marker: [],
    alertData: [],
    muteSign: true,
    searchValue: "All"
  };

  componentDidMount() {
    if (!window.google) {
      message.error("Internet error:google map");
      return;
    }
    //初始化地图
    let mapProp = {
      center: new window.google.maps.LatLng(1.458083,103.783317),
      zoom: 16,
      disableDefaultUI: true,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
    };
    map = new window.google.maps.Map(this.refs.map, mapProp);
    this.getGenfence();
    this.getWorkersList();
    this.getOutFenceAlerts();
    this.timer = setInterval(() => {
      this.getWorkersList();
      this.getOutFenceAlerts()
    }, 30000);
    this.initMute()
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getWorkersList = () => {
    httpGet("/api/v1/positions/")
      .then((res) => {
        return res.json();
      })
      .then(data => {
        let marker_show_onsite = data.filter((item, index) => {
          return item.onsite;
        });
        
        this.setState({
          marker_show_onsite,
        });
        if (this.state.searchValue !== "All" ) {
          this.onSearch(this.state.searchValue)
        } else {
          this.renderMarker(marker_show_onsite);
        }
        
      })
      .catch((err) => {
        message.error("Interface error:get_workers_list");
      });
  };

  renderMarker = (data) => {
    //地图标记
    this.clearMaker();
    this.setState({
      drawerPersonShow: false,
    });
    const all_marker = [];
    data.forEach((value, index, arr) => {
      let marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(
          value.latitude,
          value.longitude
        ),
        icon:{
          url: value.type === "person" ? userIcon : value.type  === "barge" ? bargeIcon : boatIcon,
          // anchor: new window.google.maps.Point(value.type  === "barge" ? 25: 0, value.type  === "barge" ? -6: 0)
        } 
      });
      marker.setMap(map);
      window.google.maps.event.addListener(marker, "click", () => {
        this.setState({
          drawerPersonShow: true,
          currentMarker: value,
        });
      });
      all_marker.push(marker);
      this.setState({
        all_marker,
      });
    });
  };

  getGenfence = () => {
    httpGet("/api/v1/fenced/")
      .then(res => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then(data => {
        if (data.length > 0) {
          this.renderGenfence(data);
        }
      })
      .catch((err) => {
        message.error("Interface error: get_geofence_list");
      });
  };

  clearMaker = () => {
    this.state.all_marker.forEach((item, index) => {
      item.setMap(null);
    });
  };

  renderGenfence = (genfence_arr) => {
    //地图围栏
    genfence_arr.map(item =>  {
      if(!item.is_show){
        return ''
      }
      const paths = [];
      let genfence = [];
      if (item.is_pier) {
        genfence = item.pier[0];
      } else {
        genfence = item.geo_points[0];
      }

      genfence.forEach(point => {
        paths.push(new window.google.maps.LatLng(point.G, point.K));
      })

      let flightPath = new window.google.maps.Polygon({
        path: paths,
        strokeColor: item.color,
        strokeOpacity: item.name === "Viaduct" || item.name === "MPA" ? 1 : 0,
        strokeWeight: 2,
        fillColor: item.color,
        fillOpacity: item.opacity,
        zIndex: item.name === "Viaduct" || item.name === "MPA" ? 1 : 3,
      });
      flightPath.setMap(map);
      
      if (item.name !== "Viaduct") {
        window.google.maps.event.addListener(flightPath, "click", () => {
          this.setState({
            drawerZoneShow: true,
            currentZone: item.name,
          });
          this.getZonesByName(item.name)
        });
      }
      
      new window.google.maps.Marker({
        position: { lat: item.center.G, lng: item.center.K },
        map,
        label: {
          text: item.abbr,
          fontSize: "16px",
          color: '#999'
        },
        icon: {
          url: noneIcon
        }
      });

      return ''
    });
  };

  onSearch = (value) => {
    this.setState({
      searchValue: value
    })
    this.clearMaker();
    this.setState({
      drawerPersonShow: false,
    });
    let marker_search = this.state.marker_show_onsite.filter((item, index) => {
      return item.name.toUpperCase() === value.toUpperCase();
    });
    if (marker_search.length > 0) {
      map.setCenter(
        new window.google.maps.LatLng(
          marker_search[0].latitude,
          marker_search[0].longitude
        )
      );
      this.renderMarker(marker_search);
    } else {
      map.setCenter(new window.google.maps.LatLng(1.458083,103.783317));
      this.renderMarker(this.state.marker_show_onsite);
    }
  };
  getOutFenceAlerts = () => {
    httpGet("/api/v1/alerts/day/")
      .then((res) => {
        return res.json();
      })
      .then(data => {
        this.setState({
          alertData: data,
        });
        if (this.state.alertData.length > 0 && !this.state.muteSign) {   
          // this.rap.audioEl.current.play()
        }
      })
      .catch((err) => {
        message.error("Interface error: get_out_fence_alerts");
      });
  };
  onAlert = (item) => {
    httpGet("/api/v1/alerts/" + item.id + "/viewed/")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        localStorage.setItem("location", JSON.stringify(item));
        this.props.history.push("./location");
      })
      .catch((err) => {
        message.error("Interface error: get_alert_view");
      });
    
  }
  onClose = (item) => {
    httpGet("/api/v1/alerts/" + item.id + "/viewed/")
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.getOutFenceAlerts()
      })
      .catch((err) => {
        message.error("Interface error: get_alert_view");
      });
  }
  initMute = () => {
    let _mute = sessionStorage.getItem("muteSign")
    if (_mute) {
      this.setState({
        muteSign: _mute === "true"
      })
    } else {
      sessionStorage.setItem("muteSign", this.state.muteSign)
    }
  }
  handleMute = () => {
    this.setState({
      muteSign: !this.state.muteSign
    })

    sessionStorage.setItem("muteSign", !this.state.muteSign)
  }
  formateAlertMsg = (item) => {
    const time = moment(item.time).format(dateFormat)
    return  `${time} ${item.message}`
  }
  handleZone = (flag) => {
    this.setState({
      drawerZoneShow: !!flag,
    });

    if(!!!flag) {
      this.setState({
        zoneData: []
      })
    }
  };

  getZonesByName = (name) => {
    httpGet("/api/v1/zones/" + name )
      .then((res) => {
        return res.json();
      })
      .then(data => {
        this.setState({
          zoneData: data,
        });
      })
      .catch((err) => {
        message.error("Interface error:get_zones_list");
      });
  };
  render() {

    return (
      <div className="home">
        <div className="info">
          <span className="onsite">
            On site:&nbsp;
            <b>{this.state.marker_show_onsite.length}</b>
          </span>
          <span className="search">
            <Select
              defaultValue={this.state.searchValue}
              showSearch
              style={{ width: 200 }}
              onChange={this.onSearch}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key={0} value="All">
                All
              </Option>
              {this.state.marker_show_onsite.map((item) => (
                <Option key={item.device_label} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </span>
        </div>
        <div
          id="googleMap"
          ref="map"
          style={{ width: "100%", height: "calc(100vh - 105px)" }}
        ></div>
        <Person
          show={this.state.drawerPersonShow}
          data={this.state.currentMarker}
        ></Person>
        <Zone
          show={this.state.drawerZoneShow}
          name={this.state.currentZone}
          data={this.state.zoneData}
          handleZone={this.handleZone}
        ></Zone>
        <div className="alert-list">
          {
            this.state.alertData.map((item) => (
              <Alert 
                key={item.id} 
                message={this.formateAlertMsg(item)}
                type="warning" 
                showIcon 
                closable
                closeText={
                  [
                    <Icon type="close" key="close" 
                      style={{fontSize: 16, marginLeft: 6,marginTop: 3}} 
                      onClick={() => this.onClose(item)} />
                  ]
                }
              />
            ))
          }
          
          
        </div>
        
      </div>
    );
  }
}
