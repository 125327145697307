import { getToken } from '../utils/auth'

function isToken() {
  const token = getToken();
  if (token) {
    return token
  } else {
    // window.location.href="/login"
  }
}

// get
export function httpGet(url){
    const token = isToken()

	  var result = fetch(url, {
      headers: {
        "authorization": `Bearer ${token}`
      }
    });
	  return result;
}


// post
export function httpPost(url, data){ 
  const token = isToken()

   var result = fetch(url,{
	     method:"post",
	     headers: {
	        'Accept': 'application/json',
	        'Content-Type': 'application/json',
          "authorization": `Bearer ${token}`
	     },
	     body:data
   })
   return result;
}

// put
export function httpPut(url, data){ 
  const token = isToken()

  var result = fetch(url,{
      method:"put",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "authorization": `Bearer ${token}`
      },
      body:data
  })
  return result;
}

// delete
export function httpDelete(url, data){ 
  const token = isToken()
  var result = fetch(url,{
      method:"delete",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "authorization": `Bearer ${token}`
      },
      body:data
  })
  return result;
}

export function params(obj){
	  var result = ""; 
	  var item;
	  for(item in obj){
	    result += "&"+item+"="+encodeURIComponent(obj[item]);
	  }
	  if(result){
	    result = result.slice(1)
	  }
	  return result;
}
