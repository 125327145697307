import React from "react";
import { Drawer, Table } from "antd";
import "./Home.scss";

import moment from 'moment'
const dateFormat = 'YYYY-MM-DD HH:mm'

class Zone extends React.Component {
  state = {
    pageSize: 10
  };
  columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Last Seen",
      align: "center",
      render: (text, record) => (
        <span>
          {moment(record.device.update_time).format(dateFormat)}
        </span>
      ),
    },
  ]

  onShowSizeChange = (current, size) => {
    this.setState({
      pageSize: size
    })
  }
  render() {
    const { show, name, data, handleZone } = this.props;
    return (
      <div>
        <Drawer width={500} onClose={ () => handleZone(false)} visible={show}>
          <div className="drawerZone">
            <h2 style={{textAlign: 'center', fontSize: 30}}>{name}</h2>
            
            <Table
              rowKey={(record) => record.id}
              columns={this.columns}
              dataSource={data}
              pagination={
                {
                  pageSize: this.state.pageSize,
                  showSizeChanger: true,
                  onShowSizeChange: this.onShowSizeChange
                }
              }
            ></Table>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default Zone;
