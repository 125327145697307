import React from "react";
import { httpPut } from "../../http";
import {
  message,
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
} from "antd";

const FormItem = Form.Item;

class AddFormContainer extends React.Component {
  state = {
  };

  render() {
    const {
      modalVisibleBind,
      form,
      handleModalVisibleBind,
      getWorkersList,
      modalTitleBind,
      initData,
    } = this.props;

    const okHandle = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        
        if (initData.id) {
          let submit_value = {
            ...fieldsValue
          };
          httpPut("/api/v1/workers/" + initData.id + "/bind/", JSON.stringify(submit_value))
            .then( res => {
              if (res.status === 200) {
                handleModalVisibleBind();
                getWorkersList();
                form.resetFields();
              } else {
                message.error("Interface error: bind_device");
              }
            })
            .catch((err) => {
              message.error("Interface error: bind_device");
            });
        } 
        
      });
    };

    const unbindHandle = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        
        if (initData.id) {
          let submit_value = {
            device_label: null
          };
          httpPut("/api/v1/workers/" + initData.id + "/bind/", JSON.stringify(submit_value))
            .then( res => {
              if (res.status === 200) {
                handleModalVisibleBind();
                getWorkersList();
                form.resetFields();
              } else {
                message.error("Interface error: unbind_device");
              }
            })
            .catch((err) => {
              message.error("Interface error: unbind_device");
            });
        } 
        
      });
    };
    
    return (
      <Modal
        destroyOnClose
        title={modalTitleBind}
        visible={modalVisibleBind}
        okText="Bind"
        onOk={okHandle}
        width={400}
        onCancel={() => handleModalVisibleBind()}
        className="profiles-form"
      >
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={10}>
            <Col span={18}>
              <FormItem label="">
                {form.getFieldDecorator("device_label", {
                  rules: [
                    {
                      message: "Please input device label.",
                    },
                  ],
                  initialValue: initData.device_label,
                })(<Input placeholder="Device Label"/>)}
              </FormItem>
            </Col>
            <Col span={6}>
              <Button
                type="danger"
                disabled={!initData.device_label}
                onClick={unbindHandle}
                >Unbind
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
const AddForm = Form.create()(AddFormContainer);
export default AddForm;
