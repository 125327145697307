import React from "react";
import { message, Icon } from "antd";
import { httpGet } from "../../http";
import { withRouter } from "react-router-dom";

import "./zones.scss"

class Zones extends React.Component {
  state = {
    data: []
  };

  componentDidMount() {
    this.getZonesData()
    this.timer = setInterval(() => {
      this.getZonesData()
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getZonesData = () => {
    httpGet("/api/v1/zones/")
      .then( res => {
        if (res.status === 200) {
          return res.json();
        } else {
          message.error("Interface error: get_zone_list");
        }
        
      })
      .then( data => {
        this.setState({
          data: data
        });
        
      })
      .catch((err) => {
        message.error("Interface error: get_zone_list");
      });
  };

  render() {
    return (
      <div className="zones-box">
        { this.state.data.map( item => {
          return (<div key={item.zone} className={item.zone === 'Total' ? 'total' : 'zone'}>
                    <div className={item.zone.length > 10 ? 'wrap title' : 'title'}>
                      {item.zone.length > 0 ? item.zone : "Others"}
                      </div>
                    <div className="msg">
                      <Icon type="team"  style={{fontSize: '36px'}}/>
                      <span>{item.count}</span>
                    </div>
                  </div>)
        })}
      </div>
    );
  }
}

export default withRouter(Zones);
