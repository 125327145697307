import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { Button, message } from 'antd';
import './Outline.scss';
import { httpPost } from "../../http";
import { removeToken } from "../../utils/auth"
import { Layout, Menu, Icon, Dropdown, Avatar } from 'antd';

import homeLogo from '../../images/logo.png';

const { Header, Sider, Content, Footer } = Layout;

const LeftSider = withRouter(({ history,collapsed,collapsedClose }) => {
    let is_staff = sessionStorage.getItem("is_staff")
    is_staff = JSON.parse(is_staff)
    const profile = is_staff ? 
                              <Menu.Item key="/profiles" onClick={collapsedClose}>
                                  <Link to="/profiles">
                                    <Icon type="unordered-list" style={{fontSize: '24px'}}/>
                                    <span>Profiles</span>
                                  </Link>
                              </Menu.Item> : 
                              <Menu.Item key="/profiles" onClick={collapsedClose}>
                                  <Link to="/profiles">
                                    <Icon type="unordered-list" style={{fontSize: '24px'}}/>
                                    <span>Profiles</span>
                                  </Link>
                              </Menu.Item>
    return (<Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
    >
        <div className="logo">
          <a href="/home">
            <img src={homeLogo} alt=""  /> {collapsed ? "" : <span><strong>TF</strong>LOCATION</span>}
          </a>               
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[history.location.pathname]}>
            <Menu.Item key="/home" onClick={collapsedClose}>
                <Link to="/home">
                  <Icon type="home" style={{fontSize: '24px'}}/>
                  <span>Home</span>
                </Link>
            </Menu.Item>
            {profile}
            <Menu.Item key="/zones" onClick={collapsedClose}>
                <Link to="/zones">
                  <Icon type="monitor" style={{fontSize: '24px'}}/>
                  <span>Zones</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/alert" onClick={collapsedClose}>
                <Link to="/alert">
                  <Icon type="warning" style={{fontSize: '24px'}}/>
                  <span>Alert</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/logs" onClick={collapsedClose}>
                <Link to="/logs">
                  <Icon type="history" style={{fontSize: '24px'}}/>
                  <span>Logs</span>
                </Link>
            </Menu.Item>
        </Menu>
    </Sider>)
})

class App extends React.Component {
  
  state = {
    collapsed: true,
    defaultSelectedKeys:"home",
  };
  
  collapsedClose = () => {
      this.setState({
        collapsed: true,
      });
  };

  toggle = () => {
      this.setState({
        collapsed: !this.state.collapsed,
      });
  }

  changeSelectedKeys = (key) => {
    this.collapsedClose();
    this.setState({
      defaultSelectedKeys: key,
    });
  }

  exit = ()=>{
    const subData = {
      "refresh": sessionStorage.getItem("refresh")
    }
    httpPost("/api/v1/token/logout/", JSON.stringify(subData))
    
    .then(res => {
        console.log(res);
        if(res.status === 200){
          sessionStorage.setItem("isLogin","0");
          sessionStorage.setItem("refresh","");
          removeToken()
          this.props.history.push('./login');
        }
      })  
      .catch(err => {
          message.error('Interface error:logout');
      })
  }

  render() {
    const menu = (
        <Menu>
          <Menu.Item>
            <Button type="link" rel="noopener noreferrer" icon="poweroff" onClick={this.exit}>Log out</Button>
          </Menu.Item>
        </Menu>
    )
    const username = sessionStorage.getItem("username");

    return (
      <div className="customize">
        <Layout>
            <LeftSider collapsed={this.state.collapsed} collapsedClose={this.collapsedClose}/>
            <Layout>
                <Header style={{ background: '#f1f1f1', padding: 0}}>
                    <Icon
                      className="trigger"
                      type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                      onClick={this.toggle}
                    />
                    <div className="user">
                      <Dropdown overlay={menu} placement="bottomLeft">
                          <div>
                            <Avatar src="" />
                            <span>{username}</span>
                          </div>
                      </Dropdown>
                    </div>
                </Header>
                <Content className="content">
                    {this.props.children}
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    TFLOCATION ©2023 Created by Techfox
                </Footer>
            </Layout>
        </Layout>
      </div> 
    );
  }
}


export default withRouter(App);



