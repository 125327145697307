import React, { Component } from "react";
import { Switch } from 'react-router'
import AuthRouter from "../Router/AuthRouter.js";
import Outline from "../Outline";

import Home from "../Home";
import Profiles from "../Profiles";
import ProfilesLow from "../ProfilesLow";
import Geofencing from "../Geofencing";
import History from "../History";
import Alert from "../Alert";
import Location from "../Location";
import Zones from "../Zones";
import Logs from "../Logs"
import NotFound from "../404"

export default class SubRouter extends Component {
  render() {
    let is_staff = sessionStorage.getItem("is_staff")
    is_staff = JSON.parse(is_staff)
    const profile = is_staff ? 
                              <AuthRouter path="/profiles" component={Profiles}></AuthRouter> : 
                              <AuthRouter path="/profiles" component={ProfilesLow}></AuthRouter>
    return (
      <Outline>
        <Switch>
          <AuthRouter exact path="/" component={Home}></AuthRouter>
          <AuthRouter path="/home" component={Home}></AuthRouter>
          {profile}
          <AuthRouter path="/geofencing" component={Geofencing}></AuthRouter>
          <AuthRouter path="/history" component={History}></AuthRouter>
          <AuthRouter path="/alert" component={Alert}></AuthRouter>
          <AuthRouter path="/location" component={Location}></AuthRouter>
          <AuthRouter path="/zones" component={Zones}></AuthRouter>
          <AuthRouter path="/logs" component={Logs}></AuthRouter>

          <AuthRouter path="*" component={NotFound}></AuthRouter>
        </Switch>
      </Outline>
    );
  }
}
