import React, { Component } from 'react';
import {Form, Input, Row, Col, Button, message} from 'antd';

import { httpPost } from "../../http"

import './register.css'

class ResetPsdForm extends Component {
  state = {
    isHandle: false,
  }
  
  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <div className="register-box">
        <div className="register-content">
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label="Username">
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Please input your username!'}],
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Token">
              <Row gutter={8}>
                <Col span={12}>
                  {getFieldDecorator('verification_code', {
                    rules: [{ required: true, message: 'Please input the token you got!' }],
                  })(<Input />)}
                </Col>
                <Col span={12}>
                  <Button onClick={this.getCode} disabled={this.state.isHandle}>Get token</Button>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label="Password">
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  
                ],
              })(<Input.Password />)}
            </Form.Item>
            
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  }

  handleSubmit = e => {
    e.preventDefault();
    
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      httpPost("/api/v1/password/reset/", JSON.stringify(values))
        .then(res => {
          console.log(res);
          if(res.status === 200) {
            this.props.history.push('/login');
          }
        })
        .catch(err => {
          message.error('Interface error: forget');
        })
    });
  };
  getCode = () => {
    const { form } = this.props;

    const username = (form.getFieldValue('username'));
    if (username) {
      this.setState({
        isHandle: true
      });
      this.timeoutID = setTimeout(() => {
        this.setState({isHandle: false})
      }, 5000)

      httpPost("/api/v1/password/forget/", JSON.stringify({username: username}))
        
        .then(data => {
          if (data.status === 200){
            message.success("Please check your email!")
          }else{
            message.error('Interface error: Reset password');
          }
        })
        .catch(err => {
          message.error('Interface error: Reset password');
        })
    }else {
      message.error("Please input your username!");
    }
  }

}
const WrappedResetPsdForm = Form.create()(ResetPsdForm);

export default WrappedResetPsdForm;