import React from "react";
import { DatePicker, Modal, Form } from "antd";
import moment from "moment";

class TimeFormContainer extends React.Component {
  state = { date_second: "" };

  okHandle = () => {
    window.location.href = "/history";
    localStorage.setItem("date", this.state.date_second);
  };

  onChange = (date, dateString) => {
    this.setState({
      date_second: moment(dateString + " 00:00:00").valueOf(),
    });
  };
  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  render() {
    const { modalVisibleTime, handleModalVisibleTime, workerId, type } = this.props;
    localStorage.setItem("workerId", workerId);
    localStorage.setItem("type", type);

    return (
      <Modal
        destroyOnClose
        title="Please select time period"
        visible={modalVisibleTime}
        okText="Save"
        width={400}
        onCancel={() => handleModalVisibleTime(false, [])}
        onOk={this.okHandle}
      >
        <Form layout="vertical" hideRequiredMark>
          <Form.Item label="Date Picker">
            <DatePicker
              disabledDate={this.disabledDate}
              onChange={this.onChange}
              style={{ width: "350px" }}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
const TimeForm = Form.create()(TimeFormContainer);
export default TimeForm;
