import React from "react";
import { Table, message } from "antd";
import { httpGet } from "../../http";
import { withRouter } from "react-router-dom";

import moment from 'moment'
import './logs.css'
const dateFormat = 'YYYY-MM-DD HH:mm'

class Logs extends React.Component {
  state = {
    data: [],
    pageSize: 10
  };

  componentDidMount() {
    this.getLogsList();
  }

  getLogsList = () => {
    httpGet("/api/v1/gps/zones/")
      .then((res) => {
        return res.json();
      })
      .then(data => {
        this.setState({
          data: data,
        });
      })
      .catch((err) => {
        message.error("Interface error:get_logs_list");
      });
  };
  
  onShowSizeChange = (current, size) => {
    this.setState({
      pageSize: size
    })
  }
  columns = [
    {
      title: "ID",
      key: "worker_id",
      render: (text, record, index) => `${index + 1}`
    },
    {
      title: "Worker Name",
      dataIndex: "worker_name",
      key: "worker_name",
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
    },
    {
      title: "Time",
      align: "center",
      dataIndex: "time",
      key: "time",
      render: (text, record) => (
        <span>
          {moment(record.time).format(dateFormat)}
        </span>
      ),
    },
  ];

  render() {
    return (
      <div className="box logs">
        <Table
          rowKey={(record,index) => index+1}
          columns={this.columns}
          dataSource={this.state.data}
          pagination={{
            current: this.state.current,
            onChange: (Page) => {
              this.setState({
                current: Page,
              });
            },
            pageSize: this.state.pageSize,
              showSizeChanger: true,
              onShowSizeChange: this.onShowSizeChange
          }}
        ></Table>
      </div>
    );
  }
}

export default withRouter(Logs);
