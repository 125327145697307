import React, { Component } from "react";
import moment from "moment";
import { message } from "antd";
import userIcon from "../../images/po.png";
import boatIcon from "../../images/boat.png";
import noneIcon from "../../images/none.png";
import { httpGet } from "../../http";
let map = {};

export default class Location extends Component {
  state = {
    line_marker: [],
    
  };
  componentDidMount() {
    //初始化地图
    if (!window.google) {
      message.error("Internet error:google map");
      return;
    }
    var mapProp = {
      center: new window.google.maps.LatLng(1.458083,103.783317),
      zoom: 16,
      disableDefaultUI: true,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
    };
    map = new window.window.google.maps.Map(this.refs.map, mapProp);

    let location = localStorage.getItem("location");
    this.getGenfence();
    this.renderMarker(JSON.parse(location));
    this.getAlertLocations(JSON.parse(location));
  }

  animateCircle = (line) => {
    let count = 0;
    const total = this.state.line_marker.length;
    let speed = total > 0 ? Math.ceil(total / 50) * 20 : 20;

    const timer = window.setInterval(() => {
      count = (count + 1) % 200;

      const icons = line.get("icons");

      icons[0].offset = count / 2 + "%";
      line.set("icons", icons);

      if (count >= 199) {
        clearInterval(timer);
      }
    }, speed);
  };
  renderLine = () => {
    const lineSymbol = {
      path: window.google.maps.SymbolPath.CIRCLE,
      scale: 8,
      strokeColor: "#393",
    };

    const line = new window.google.maps.Polyline({
      path: this.state.line_marker,
      icons: [
        {
          icon: lineSymbol,
          offset: "0%",
        },
      ],
      strokeColor: "#FF0000",
      map: map,
    });

    this.animateCircle(line);
  };
  renderMarker = (data) => {
    const infoWindow = new window.google.maps.InfoWindow();

    let marker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(data.latitude, data.longitude),
      icon: data.worker_type === "person" ? userIcon : boatIcon,
      title: ` Name: ${data.worker_name} <br/><br />
               Device Label: ${data.device_label} <br/><br />
               Time: ${moment(data.time).format("YYYY-MM-DD HH:mm:ss")}`
    });
    marker.addListener("click", () => {
      infoWindow.close();
      infoWindow.setContent(marker.getTitle());
      infoWindow.open(marker.getMap(), marker);
    });
    marker.setMap(map);
    map.setCenter(new window.google.maps.LatLng(data.latitude, data.longitude));
  };

  renderGenfence = (genfence_arr) => {
    //地图围栏
    genfence_arr.map(item =>  {
      if(!item.is_show){
        return ''
      }
      const paths = [];
      let genfence = [];
      if (item.is_pier) {
        genfence = item.pier[0];
      } else {
        genfence = item.geo_points[0];
      }

      genfence.forEach(point => {
        paths.push(new window.google.maps.LatLng(point.G, point.K));
      })

      let flightPath = new window.google.maps.Polygon({
        path: paths,
        strokeColor: item.color,
        strokeOpacity: item.name === "Viaduct" || item.name === "MPA" ? 1 : 0,
        strokeWeight: 2,
        fillColor: item.color,
        fillOpacity: item.opacity,
        zIndex: item.name === "Viaduct" || item.name === "MPA" ? 1 : 3,
      });
      flightPath.setMap(map);

      new window.google.maps.Marker({
        position: { lat: item.center.G, lng: item.center.K },
        map,
        label: {
          text: item.abbr,
          fontSize: "16px",
          color: '#999'
        },
        icon: {
          url: noneIcon
        }
      });

      return ''
    });
  };

  getGenfence = () => {
    httpGet("/api/v1/fenced/")
      .then(res => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then(data => {
        if (data.length > 0) {
          this.renderGenfence(data);
        }
      })
      .catch((err) => {
        message.error("Interface error: get_geofence_list");
      });
  };

  getAlertLocations = (data) => {
    const time = moment(data.time).format("YYYY-MM-DD HH:mm:ss")

    httpGet("/api/v1/gps/alert/" + data.worker_id + "/" + time + "/")
      .then((res) => {
        return res.json();
      })
      .then(data => {
        
          this.setState({
            line_marker: data,
          });
          
          this.renderLine();
        
      })
      .catch((err) => {
        message.error("Interface error: get_alert_locations");
      });
  };
  render() {
    return (
      <div className="location">
        <div
          id="googleMap"
          ref="map"
          style={{ width: "100%", height: "calc(100vh - 105px)" }}
        ></div>
        {/* <LocationRight></LocationRight> */}
      </div>
    );
  }
}
