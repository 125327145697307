import React from "react";
import { httpPost, httpPut } from "../../http";
import {
  message,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select
} from "antd";

import "./profiles.css"

const { Option } = Select;

const FormItem = Form.Item;

class AddFormContainer extends React.Component {
  state = {
    photo_url: "",
  };

  render() {
    const {
      modalVisibleAdd,
      form,
      handleModalVisibleAdd,
      getWorkersList,
      modalTitleAdd,
      initData,
    } = this.props;

    const okHandle = () => {
      form.validateFields((err, fieldsValue) => {
        if (err) return;
        
        if (initData.id) {
          let submit_value = {
            ...fieldsValue
          };
          httpPut("/api/v1/workers/" + initData.id + "/", JSON.stringify(submit_value))
            .then( res => {
              if (res.status === 200) {
                handleModalVisibleAdd();
                getWorkersList();
                form.resetFields();
              } else {
                message.error("Interface error: update_worker");
              }
            })
            .catch((err) => {
              message.error("Interface error: update_worker");
            });
        } else {
          let submit_value = {
            ...fieldsValue,
          };
          httpPost("/api/v1/workers/", JSON.stringify(submit_value))
            .then( res  => {
              if (res.status === 201) {
                handleModalVisibleAdd();
                getWorkersList();
                form.resetFields();
              } else {
                 message.error("Interface error:add_worker");
              }
            })
            .catch((err) => {
              message.error("Interface error:add_worker");
            });
        }
        
      });
    };
    
    return (
      <Modal
        destroyOnClose
        title={modalTitleAdd}
        visible={modalVisibleAdd}
        okText="Save"
        onOk={okHandle}
        width={400}
        onCancel={() => handleModalVisibleAdd()}
        className="profiles-form"
      >
        <Form layout="vertical" hideRequiredMark>
          <Row gutter={16}>
            <Col span={24}>
              <FormItem label="">
                {form.getFieldDecorator("company", {
                  rules: [
                    {
                      message: "Please input company.",
                    },
                  ],
                  initialValue: initData.company,
                })(<Input placeholder="Company"/>)}
              </FormItem>
              <FormItem label="">
                {form.getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: "Please input name.",
                    },
                  ],
                  initialValue: initData.name,
                })(<Input placeholder="Name"/>)}
              </FormItem>
              <FormItem label="">
                {form.getFieldDecorator("nric", {
                  rules: [
                    {
                      message: "Please input nric/fin no.",
                    },
                  ],
                  initialValue: initData.nric,
                })(<Input placeholder="NRIC/Fin no"/>)}
              </FormItem>
              <FormItem label=''>
                {form.getFieldDecorator('type', {
                  rules: [
                    {
                      required: false,
                      message: 'Please Select Type.',
                    },
                  ],
                  initialValue: initData.type,
                })(
                  <Select
                    placeholder="Type"
                  >
                    <Option value="person" key="person">Person</Option>
                    <Option value="ferry" key="ferry">Ferry</Option>
                    <Option value="barge" key="barge">Barge</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}
const AddForm = Form.create()(AddFormContainer);
export default AddForm;
