import React, { Component } from "react";
import moment from "moment";
import { Form, Slider, message, Spin } from "antd";
import userIcon from "../../images/po.png";
import boatIcon from "../../images/boat.png";
import noneIcon from "../../images/none.png";
import { httpGet } from "../../http";
let map = {};

export default class History extends Component {
  state = {
    line_marker: [],
    start_time: '',
    end_time: '',
    show_marker: undefined,
    loading: false,
    type: "person"
  };

  componentDidMount() {
    //初始化地图
    var mapProp = {
      center: new window.google.maps.LatLng(1.458083,103.783317),
      zoom: 16,
      disableDefaultUI: true,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
    };
    map = new window.window.google.maps.Map(this.refs.map, mapProp);

    this.getGenfence();
    this.getWorkersList();
    // this.renderLine()

    const type = localStorage.getItem("type")
    this.setState({
      type
    })
  }

  getGenfence = () => {
     httpGet("/api/v1/fenced/")
      .then(res => {
        if (res.status === 200) {
          return res.json();
        }
      })
      .then(data => {
        if (data.length > 0) {
          this.renderGenfence(data);
        }
      })
      .catch((err) => {
        message.error("Interface error: get_geofence_list");
      });
  };
  getWorkersList = () => {
    const date = moment(Number(localStorage.getItem("date"))).format("YYYY-MM-DD");

    const worker_id = localStorage.getItem("workerId")
      ? localStorage.getItem("workerId").split(",")
      : [];

    const submitValue = { 
      time: date, 
      worker_id: worker_id.length > 0 ? Number(worker_id[0]) : undefined 
    };

    this.setState({
      loading: true,
    });
    httpGet("/api/v1/gps/locations/" + submitValue.worker_id + "/" + submitValue.time + "/")
      .then((res) => {
        return res.json();
      })
      .then(data => {
        const _data = data

        this.setState({
          loading: false, 
          line_marker: _data,
        });
        if (_data.length > 0) {
          this.setState({
            start_time: _data[0].time,
            end_time: _data[_data.length -1].time,
            range:_data.length
          })
          this.renderLine()
        }
      })
      .catch((err) => {
        message.error("Interface error:get_location_trace");
      });
  };

  clearMaker = () => {
    if (this.state.show_marker){
      this.state.show_marker.setMap(null);
    }
    
  };

  change = (index) => {
    this.clearMaker();
    
    let marker = new window.google.maps.Marker({
      position: new window.google.maps.LatLng(this.state.line_marker[index].lat, this.state.line_marker[index].lng),
      icon: this.state.type === "person" ? userIcon : boatIcon,
    });
    marker.setMap(map);
    this.setState({
      show_marker: marker
    })

  };

  animateCircle = (line) => {
    let count = 0;
    const total = this.state.line_marker.length
    let speed =  total > 0 ? Math.ceil(total/50)*20 : 20

    const timer = window.setInterval(() => {
      
      count = (count + 1) % 200;

      const icons = line.get("icons");

      icons[0].offset = count / 2 + "%";
      line.set("icons", icons);

      if (count >= 199) {
        clearInterval(timer)
      }
    }, speed);
  };

  renderLine = () => {
    map.setCenter(new window.google.maps.LatLng(this.state.line_marker[0].lat, this.state.line_marker[0].lng));
    const lineSymbol = {
      path: window.google.maps.SymbolPath.CIRCLE,
      scale: 8,
      strokeColor: "#393",
    };
    
    const line = new window.google.maps.Polyline({
      path: this.state.line_marker,
      icons: [
        {
          icon: lineSymbol,
          offset: "0%",
        },
      ],
      strokeColor: "#FF0000",
      map: map,
    });

    this.animateCircle(line);
  }

  renderGenfence = (genfence_arr) => {
    //地图围栏
    genfence_arr.map(item =>  {
      if(!item.is_show){
        return ''
      }
      const paths = [];
      let genfence = [];
      if (item.is_pier) {
        genfence = item.pier[0];
      } else {
        genfence = item.geo_points[0];
      }

      genfence.forEach(point => {
        paths.push(new window.google.maps.LatLng(point.G, point.K));
      })

      let flightPath = new window.google.maps.Polygon({
        path: paths,
        strokeColor: item.color,
        strokeOpacity: item.name === "Viaduct" || item.name === "MPA" ? 1 : 0,
        strokeWeight: 2,
        fillColor: item.color,
        fillOpacity: item.opacity,
        zIndex: item.name === "Viaduct" || item.name === "MPA" ? 1 : 3,
      });
      flightPath.setMap(map);

      new window.google.maps.Marker({
        position: { lat: item.center.G, lng: item.center.K },
        map,
        label: {
          text: item.abbr,
          fontSize: "16px",
          color: '#999'
        },
        icon: {
          url: noneIcon
        }
      });

      return ''
    });
  };

  

  render() {
    
    return (
      <Spin spinning={this.state.loading} size="large" type="loading">
        <div className="history">
          <div className="range">
            <Form layout="vertical" hideRequiredMark>
              <Form.Item>
                <Slider
                  min={0}
                  max={this.state.range-1}
                  marks={{
                    0: this.state.start_time,
                    [this.state.range]: this.state.end_time,
                  }}
                  tipFormatter={ index => {
                    const time = this.state.line_marker[index] ? this.state.line_marker[index].time : ''
                    return time;
                  }}
                  onChange={this.change}
                />
                ,
              </Form.Item>
            </Form>
          </div>
          <div
            id="googleMap"
            ref="map"
            style={{ width: "100%", height: "calc(100vh - 105px)" }}
          ></div>
        </div>
      </Spin>
    );
  }
}
