import React from "react";
import {
  Table,
  Divider,
  Popconfirm,
  message,
  Button,
  Form,
  Input,
  Icon
} from "antd";
import { httpGet, httpDelete } from "../../http";
import AddForm from "./AddForm.js";
import BindForm from "./BindForm";
import TimeForm from "./TimeForm.js";
import "./profiles.css"
const { Search } = Input;

class ListForm extends React.Component {
  state = {
    modalVisibleAdd: false,
    modalTitleAdd: "",
    editValues: {},
    modalVisibleBind: false,
    modalTitleBind: "",
    editBind: {},
    modalVisibleTime: false,
    selectedRowKeys: [],
    data: [],
    type: "person",
    pageSize: 10
  };

  componentDidMount() {
    this.getWorkersList(0);
  }

  getWorkersList = () => {
    httpGet("/api/v1/workers/")
      .then( res => {
        if (res.status === 200) {
          return res.json();
        } else {
          message.error("Interface error: get_workers_list");
        }
        
      })
      .then( data => {
        this.setState({
          data: data
        });
        
      })
      .catch((err) => {
        message.error("Interface error: get_workers_list");
      });
  };

  downloadWorkersList = () => {
    let url = "/api/v1/worker/download";
    window.open(url);
  };

  handleModalVisibleAdd = (flag, title, record) => {
    this.setState({
      modalVisibleAdd: !!flag,
      modalTitleAdd: title,
      editValues: record || {},
    });
  };

  handleModalVisibleBind = (flag, title, record) => {
    this.setState({
      modalVisibleBind: !!flag,
      modalTitleBind: title,
      editBind: record || {},
    });
  };

  handleModalVisibleTime = (flag, worker_id, type) => {
    console.log(worker_id)
    this.setState({
      modalVisibleTime: !!flag,
      selectedRowKeys: worker_id,
      type: type
    });
  };

  delete_confirm = (id) => {
    httpDelete("/api/v1/workers/" + id + "/")
      .then( res => {
        if (res.status === 204) {
          message.info("Delete Success!");
          this.getWorkersList();
        } else {
          message.error("Interface error: delete_worker");
        }
      })
      .catch((err) => {
        message.error("Interface error: delete_worker");
      });
  };

  columns = [
    {
      title: "On Site",
      dataIndex: "onsite",
      align: "center",
      key: "onsite",
      render: (text, record) => (
        <span>
          {record.device && record.device.onsite ? <b className="green"></b> : <b className="red"></b>}
        </span>
      ),
      sorter: (a, b) => ((a.device ? a.device.onsite : false) - (b.device ? b.device.onsite : false))
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "NRIC/Fin no",
      dataIndex: "nric",
      key: "nric",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text, record) => (
        <span>{record.type && (record.type.slice(0,1).toUpperCase() + record.type.slice(1).toLowerCase())}</span>
      )
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Device Label",
      dataIndex: "device_label",
      key: "device_label",
      align: "center"
    },
    {
      title: "Movement",
      dataIndex: "movement",
      key: "movemoent",
      align: "center",
      render: (text, record) => (
        <Icon 
          type="history" 
          style={{ fontSize: 22, cursor: 'pointer', color: '#1890ff' }}
          onClick={() => this.handleModalVisibleTime(true, [record.id], record.type)}
        />
      ),
    },

    {
      title: <div className="addWorker"
              onClick={() => this.handleModalVisibleAdd(true, "Add Profiles", {})}> 
              <Icon type="plus-circle" style={{ fontSize: 22, cursor: 'pointer', color: '#1F68EA' }}/>
              <span className="text">Add New Worker</span>
             </div>,
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (text, record) => (
        <span>
          <Button
            type="link"
            onClick={() =>
              this.handleModalVisibleAdd(true, "Edit Profiles", record)
            }
          >
            Edit
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            placement="top"
            title="Are you sure to delete this task?"
            onConfirm={() => this.delete_confirm(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </span>
      ),
    },
    {
      title: "Bind",
      dataIndex: "bind",
      key: "bind",
      align: "center",
      render: (text, record) => (
        <Icon 
          type="link" 
          style={{ fontSize: 22, cursor: 'pointer', color: '#1890ff' }}
          onClick={() => this.handleModalVisibleBind(true, "Bind", record)}
        />
      ),
    },
  ];

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onSearch = (value) => {
    let workerSearch = this.state.data.filter((item, index) => {
      let name = item.name.toUpperCase().indexOf(value.toUpperCase()) !== -1 ;
      if (name) {
        return item;
      }
      return ''
    });

    if (workerSearch.length > 0) {
      this.setState({
        data: workerSearch,
      });
    } else {
      message.error("error: please enter the correct content");
    }
  };
  resetWorkList = () => {
    this.refs.search.input.state.value= undefined
    this.getWorkersList(0)
  };
  onShowSizeChange = (current, size) => {
    this.setState({
      pageSize: size
    })
  }

  render() {
    const parentMethodsAdd = {
      handleModalVisibleAdd: this.handleModalVisibleAdd,
    };
    const parentMethodsBind = {
      handleModalVisibleBind: this.handleModalVisibleBind,
    };
    const parentMethodsTime = {
      handleModalVisibleTime: this.handleModalVisibleTime,
    };

    return (
      <div className="box profiles">
        <div className="table-operations">
          <Search
            ref="search"
            placeholder="Search"
            enterButton
            onSearch={this.onSearch}
            style={{ width: 300, marginLeft: 10, display: 'none'}}
           
          />
          <Button 
            onClick={() => this.resetWorkList()} 
            type="primary"
            style={{ marginLeft: 1, display: 'none' }}>
            Reset
          </Button>

          <Button 
            icon="download" 
            onClick={() => this.downloadWorkersList()} 
            type="primary"
            style={{display: 'none'}}
            >Download</Button>
        </div>
        <Table
          rowKey={(record) => record.id}
          columns={this.columns}
          dataSource={this.state.data}
          pagination={
            {
              pageSize: this.state.pageSize,
              showSizeChanger: true,
              onShowSizeChange: this.onShowSizeChange
            }
          }
        ></Table>
        <AddForm
          {...parentMethodsAdd}
          type={this.state.type}
          getWorkersList={this.getWorkersList}
          modalVisibleAdd={this.state.modalVisibleAdd}
          modalTitleAdd={this.state.modalTitleAdd}
          initData={this.state.editValues}
        />
        <BindForm
          {...parentMethodsBind}
          getWorkersList={this.getWorkersList}
          modalVisibleBind={this.state.modalVisibleBind}
          modalTitleBind={this.state.modalTitleBind}
          initData={this.state.editBind}
        />
        <TimeForm
          {...parentMethodsTime}
          modalVisibleTime={this.state.modalVisibleTime}
          workerId={this.state.selectedRowKeys}
          type = {this.state.type}
        />
      </div>
    );
  }
}

const Profiles = Form.create({ name: "add_record" })(ListForm);
export default Profiles;
