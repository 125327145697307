import React from "react";
import { Button, Form, Input, message } from "antd";
import { withRouter } from "react-router-dom";
import "./User.scss";
import { httpPost } from "../../http";
import {Link} from "react-router-dom";

import { setToken } from "../../utils/auth"

const LoginForm = Form.create()((props) => {
  const { form } = props;
  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, fieldsValue) => {
      if (err) return;
      httpPost("/api/v1/token/", JSON.stringify(fieldsValue))
        .then((res) => {
          return res.json();
        })
        .then( data => {
          
          if (data.username) {
            sessionStorage.setItem("isLogin", "1");
            sessionStorage.setItem("username", data.username);
            sessionStorage.setItem("refresh", data.refresh);
            sessionStorage.setItem("is_staff", data.is_staff);
            setToken(data.access)
            props.history.push("./home");
          } else {
            message.error("Account or password error");
          }
        })
        .catch((err) => {
          message.error("Interface error:login");
        });
    });
  };

  return (
    <div className="login">
      <Form onSubmit={handleSubmit} className="login-form">
        <div className="login-title">
          <h2>Welcome to <span>TECHFOX LOCATIONS</span></h2>
          <div className="page-name">Sign in</div>
        </div>
        <div className="login-tip">
          Enter your username or email address
        </div>
        <Form.Item className="mg-bottom">
          {form.getFieldDecorator("username", {
            rules: [{ required: true, message: "Please input your username!" }],
          })(<Input type="text" placeholder="Username or email address" />)}
        </Form.Item>
        <div className="login-tip">
          Enter your password
        </div>
        <Form.Item className="mg-bottom2">
          {form.getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your Password!" }],
          })(<Input type="password" placeholder="Password" />)}
        </Form.Item>
        
        <Form.Item className="mg-bottom3">
          <Link to="/forgot_password" className="login-form-forgot">
              Forgot Password
          </Link>
        </Form.Item>
        <Form.Item >
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Sign in 
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
});

class Login extends React.Component {
  render() {
    return (
      <div>
        <LoginForm history={this.props.history} />
      </div>
    );
  }
}

export default withRouter(Login);
